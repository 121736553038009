import { IQuestion } from '@lstv/core/types/IFaq';
import React from 'react';
import { FaqQuestion } from '~/components/FaqQuestions';

const terms: IQuestion[] = [
  {
    q: 'Contest Period',
    a: `Love Stories TV’s Wedding Film Awards begin in January 2024 and end at 11:59pm ET on March 28th, 2024. All entries must be received by the deadline. Please visit www.worldtimeserver.com or other world time database to determine local time.`,
  },
  {
    q: `Eligibility`,
    a: `Open to legal residents of all countries. Employees of Love Stories TV are not eligible to enter or win a prize. CONTESTS ARE VOID WHERE PROHIBITED OR RESTRICTED BY LAW. Subject to all federal, state, local, municipal and provincial laws and regulations.`,
  },
  {
    q: `How To Enter`,
    a: `To enter electronically, please visit the 'Enter' page or your personal dashboard on lovestoriestv.com. The person submitting the entry must be the creator of the Entry (unless noted in the category, i.e. People's Choice) and will be deemed the "Entrant." You can only use one (1) email address to enter. You may enter any number of entries in the contest categories. All entries are subject to the lovestoriestv.com terms of use.`,
  },
  {
    q: `Entry Guidelines`,
    a: `Entry must not (and entrants represent and warrant that it will not), violate or infringe any third-party copyright, trademark, trade dress, or other proprietary right of any entity or person (living or deceased), including but not limited to rights of privacy, publicity or portrayal in a false light. Entry must not make reference to any commercial/corporate advertising, including but not limited to corporate logos, brand names (other than Loves Stories TV), slogans, political, or religious statements, or be otherwise objectionable, as determined in Administrator's sole discretion. Any entry deemed inappropriate or unsuitable, in Loves Stories TV's sole discretion, will be disqualified. Entry must not contain material that promotes bigotry, racism, hatred or harm against any group or individual or promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation or age. Entry cannot defame, misrepresent or contain disparaging remarks about Loves Stories TV or any other entity associated with the contests, or their respective products and services, or other people, products or companies. By submitting an entry, you understand that if your entry is deemed to be a winner, it will be posted and distributed in various media and any such posting/distribution will be deemed made at the direction of the Entrant within the meaning of the Digital Millennium Copyright Act and the Communications Decency Act (of the U.S.).`,
  },
  {
    q: `Judging`,
    a: `There are two (2) rounds of judging: In Round One, employees of Love Stories TV will review the entries in each contest (and determine a select amount of entries, minimum 1) to proceed to Round Two based on the following judging criteria per contest (see individual categories for all details).

In Round Two, the select entries will be judged by 2-5 judges who will vote per contest. The judges will rank each finalist film and the film with the highest ranking will win that contest. Please see individual contest specifications for additional judging criteria for round two on the 'Enter' page. All decisions of the judges/Love Stories TV employees are final and not subject to appeal. Judges are able to enter their films into the contests as Love Stories TV's employees select the finalists. If a judge is a finalist in any contest, they will not be permitted to vote in that contest.`,
  },
  {
    q: `Prizes`,
    a: `- All winners will be featured on https://lovestoriestv.com/wedding-film-awards/2024/winners

- Film Of The Year, Filmmaker Of The Year and Best New Filmmaker winners will receive $1,000 each. Best Social Edit, Best International Film, Best Elopement, Best Fashion, Best LGBTQ+ Wedding, Best Music, Best Moment, Best Cinematography, Best Speech or Vows, Best Venue, Best South Asian Wedding, Best Proposal or Engagement, Best Themed Wedding, Wedding of the Year and People’s Choice category winners will receive $500 each.

- No substitution of prizes will be allowed.

- All portions of the prize(s) are non-assignable and non-transferable. Any prizes pictured in any online, television and print advertising, promotional packaging, and other contest materials are for illustrative purposes only.

- In the event there is a discrepancy or inconsistency between guidelines, disclosures or other statements contained in any such promotional materials and the terms and conditions of these Official Rules, the English version of these Official Rules shall prevail, govern and control.

- All details and other restrictions of the prize(s) not specified in these Official Rules will be determined by Love Stories TV in their sole discretion.
`,
  },
  {
    q: `Winner Notification & Verification`,
    a: `Potential winners will be announced during the awards event in 2024 (date TBD) and also contacted afterwards via email provided at time of Entry and will then be required to complete and return via email/mail, an Affidavit (or Declaration) of Eligibility, Release of Liability/Prize Acceptance Form and a Publicity Release (where imposing such condition is lawful) and Assignment of Rights (collectively, “Prize Documents”). Prize documents for potential winners will be sent to the email address submitted at the time of entry. Potential winners will be required to sign and return prize documents via email/mail within five (5) days of notification attempt or prize may be forfeited and an alternate winner may be determined. Return of any prize/prize notification as undeliverable may result in disqualification and an alternate may be determined. If a U.S. winner, a 1099 tax statement will be filed with the IRS for the winner for the value of the prize in the year the prize is fulfilled. For all non-U.S. Winners, up to 30% of the prize value may be due to the IRS to comply with U.S. tax laws. Depending on your country of residence, taxes may also be due to one or more jurisdictions.`,
  },
];

const Terms = () => {
  return (
    <>
      {terms.map((question, index) => (
        <FaqQuestion key={index} {...{ question }} id={`${index}`} newStyle />
      ))}
    </>
  );
};

export default Terms;
